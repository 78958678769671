
const routers = [
	{
		path: '/',
		name: '',
		component: resolve => require(['../components/afterSale/index.vue'], resolve),//售后空白页面-检测设备序列号验证是否过期
	},
	{
		path: '/afterSale',
		name: 'afterSale',
		component: resolve => require(['../components/afterSale/index.vue'], resolve),
	},
	{
		path: '/deviceVerify',
		name: 'deviceVerify',
		component: resolve => require(['../components/afterSale/deviceVerify.vue'], resolve),//设备序列号验证
	},
	{
		path: '/productManual',
		name: 'productManual',
		component: resolve => require(['../components/afterSale/productManual.vue'], resolve),//产品手册
	},
	{
		path: '/repairsForm',
		name: 'repairsForm',
		component: resolve => require(['../components/afterSale/repairsForm.vue'], resolve),//报修表单页面
	},
	{
		path: '/repairsRecord',
		name: 'repairsRecord',
		component: resolve => require(['../components/afterSale/repairsRecord.vue'], resolve),//报修记录页面
	},
	{
		path: '/repairsDetail',
		name: 'repairsDetail',
		component: resolve => require(['../components/afterSale/repairsDetail.vue'], resolve),//报修详情页面
	},
	{
		path: '/serviceNumber',
		name: 'serviceNumber',
		component: resolve => require(['../components/afterSale/serviceNumber.vue'], resolve),//售后电话
	},
	{
		path: '/submitComplete',
		name: 'submitComplete',
		component: resolve => require(['../components/afterSale/submitComplete.vue'], resolve),//提交成功
	}
]

export default routers
